import React, { useState, useEffect, useCallback, useRef } from "react";
import * as THREE from "three";
import { TransitiveTrustGraph } from "@ethereum-attestation-service/transitive-trust-sdk";
import { ForceGraph3D } from "react-force-graph";
import SpriteText from "three-spritetext";
import { renameGraphKeys } from "graphology-utils";
import styled from "styled-components";
import Dialog from "./components/Dialog";
import AddConnection from "./components/AddConnection";

const serializeGraph = (graph: TransitiveTrustGraph) => {
  return JSON.stringify(graph.graph.export());
};

const deserializeGraph = (serializedGraph: string) => {
  const newGraph = new TransitiveTrustGraph();
  newGraph.graph.import(JSON.parse(serializedGraph));
  return newGraph;
};

const initializeExampleGraph = (graph: TransitiveTrustGraph) => {
  // Add all the example edges here

  graph.addEdge("Alice", "Bob", 0.6, 0);
};

const AppContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.div`
  color: #333;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Links = styled.div`
  a {
    margin-left: 20px;
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SelectedNodeContainer = styled.div`
  background: #f0f0f0;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: inline-block;
`;

const SelectedNodeLabel = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const SelectedNodeValue = styled.span`
  color: #007bff;
`;

const GraphContainer = styled.div<{ height: number; width: number }>`
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
  }
`;

const App: React.FC = () => {
  const [graph, setGraph] = useState(() => {
    const savedGraph = localStorage.getItem("transitiveTrustGraph");
    if (savedGraph) {
      return deserializeGraph(savedGraph);
    } else {
      const newGraph = new TransitiveTrustGraph();
      initializeExampleGraph(newGraph);
      return newGraph;
    }
  });
  const [graphData, setGraphData] = useState<{ nodes: any[]; links: any[] }>({
    nodes: [],
    links: [],
  });
  const [source, setSource] = useState("");
  const [target, setTarget] = useState("");
  const [score, setScore] = useState<number | null>(null);
  const [clickedNode, setClickedNode] = useState<string | null>(null);
  const [clickedScore, setClickedScore] = useState<number | null>(null);
  const [nodeScores, setNodeScores] = useState<{
    [key: string]: { positive: number; negative: number; net: number };
  }>({});
  const [selectedNode, setSelectedNode] = useState<string>(
    () => graph.getNodes()[0] || ""
  );
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
  const [displayHeight, setDisplayHeight] = useState(window.innerHeight);
  const fgRef = useRef<any>();
  const [rawScores, setRawScores] = useState<{
    positiveScore: number;
    negativeScore: number;
    netScore: number;
  } | null>(null);
  const [newEdgeSource, setNewEdgeSource] = useState("");
  const [newEdgeTarget, setNewEdgeTarget] = useState("");
  const [newEdgeWeight, setNewEdgeWeight] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogNode, setDialogNode] = useState<string | null>(null);
  const [newNodeName, setNewNodeName] = useState("");

  useEffect(() => {
    localStorage.setItem("transitiveTrustGraph", serializeGraph(graph));
  }, [graph]);

  useEffect(() => {
    const handleResize = () => {
      setDisplayWidth(window.innerWidth);
      setDisplayHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateGraph = useCallback(() => {
    const nodes = graph.getNodes().map((node, index) => {
      const phi = Math.acos(-1 + (2 * index) / graph.getNodes().length);
      const theta = Math.sqrt(graph.getNodes().length * Math.PI) * phi;
      const radius = 200;
      return {
        id: node,
        x: radius * Math.sin(phi) * Math.cos(theta),
        y: radius * Math.sin(phi) * Math.sin(theta),
        z: radius * Math.cos(phi),
      };
    });
    const links = graph.getEdges().map((edge) => ({
      source: edge.source,
      target: edge.target,
      positiveWeight: edge.positiveWeight,
      negativeWeight: edge.negativeWeight,
    }));
    setGraphData({ nodes, links });
  }, [graph]);

  const updateNodeScores = useCallback(
    (source: string) => {
      const scores: {
        [key: string]: { positive: number; negative: number; net: number };
      } = {};
      const computedScores = graph.computeTrustScores(source);
      Object.entries(computedScores).forEach(([node, score]) => {
        if (node !== source) {
          scores[node] = {
            positive: score.positiveScore,
            negative: score.negativeScore,
            net: score.netScore,
          };
        }
      });
      setNodeScores(scores);
    },
    [graph]
  );

  useEffect(() => {
    updateGraph();
    updateNodeScores(selectedNode);
  }, [graph, updateGraph, updateNodeScores, selectedNode]);

  const handleCompute = () => {
    if (source && target) {
      try {
        const computedScores = graph.computeTrustScores(source, [target]);
        const targetScore = computedScores[target];
        setRawScores(targetScore);
        setScore(targetScore.netScore);
      } catch (error) {
        console.error(error);
        setRawScores(null);
        setScore(null);
      }
    }
  };

  const handleNodeClick = useCallback(
    (node: any) => {
      setSelectedNode(node.id);
      updateNodeScores(node.id);
      setSource(node.id); // Add this line to update the source input
      setNewEdgeSource(node.id);
    },
    [graph, updateNodeScores]
  );

  const handleAddEdge = useCallback(() => {
    if (newEdgeSource && newEdgeTarget) {
      const positiveWeight = Math.max(0, newEdgeWeight);
      const negativeWeight = Math.abs(Math.min(0, newEdgeWeight));
      setGraph((prevGraph) => {
        const updatedGraph = new TransitiveTrustGraph();
        updatedGraph.graph.import(prevGraph.graph.export());
        updatedGraph.addEdge(
          newEdgeSource,
          newEdgeTarget,
          positiveWeight,
          negativeWeight
        );
        return updatedGraph;
      });
      setNewEdgeSource("");
      setNewEdgeTarget("");
      setNewEdgeWeight(0);
    }
  }, [newEdgeSource, newEdgeTarget, newEdgeWeight]);

  const handleNodeRightClick = useCallback((node: any) => {
    setDialogNode(node.id);
    setNewNodeName(node.id);
    setDialogOpen(true);
  }, []);

  const handleDeleteNode = useCallback(() => {
    if (dialogNode) {
      setGraph((prevGraph) => {
        const updatedGraph = new TransitiveTrustGraph();
        updatedGraph.graph.import(prevGraph.graph.export());
        updatedGraph.graph.dropNode(dialogNode);
        return updatedGraph;
      });
      setSelectedNode((prevSelected) =>
        prevSelected === dialogNode ? graph.getNodes()[0] || "" : prevSelected
      );
      setDialogOpen(false);
    }
  }, [dialogNode, graph]);

  const handleRenameNode = useCallback(() => {
    if (dialogNode && newNodeName && dialogNode !== newNodeName) {
      setGraph((prevGraph) => {
        const updatedGraph = new TransitiveTrustGraph();
        const renamedGraph = renameGraphKeys(
          prevGraph.graph,
          { [dialogNode]: newNodeName },
          {}
        );
        updatedGraph.graph.import(renamedGraph.export());
        return updatedGraph;
      });
      setSelectedNode((prevSelected) =>
        prevSelected === dialogNode ? newNodeName : prevSelected
      );
      setDialogOpen(false);
    }
  }, [dialogNode, newNodeName]);

  const handleExportGraph = () => {
    const serializedGraph = serializeGraph(graph);
    const blob = new Blob([serializedGraph], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "transitive-trust-graph.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleImportGraph = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        try {
          const importedGraph = deserializeGraph(content);
          setGraph(importedGraph);
          setSelectedNode(importedGraph.getNodes()[0] || "");
        } catch (error) {
          console.error("Error importing graph:", error);
          alert("Invalid graph file");
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <AppContainer>
      <Header>
        <Title>Transitive Trust Graph Example</Title>
        <Links>
          <a
            href="https://github.com/ethereum-attestation-service/transitive-trust-sdk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Transitive Trust SDK
          </a>
          <a
            href="https://attest.org/Transitive-Trust.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trust Algorithm Paper
          </a>
        </Links>
      </Header>
      <SelectedNodeContainer>
        <SelectedNodeLabel>Selected node:</SelectedNodeLabel>
        <SelectedNodeValue>{selectedNode}</SelectedNodeValue>
      </SelectedNodeContainer>
      {score !== null && rawScores !== null && (
        <p>
          Trust score from {source} to {target}: +
          {rawScores.positiveScore.toFixed(4)} / -
          {rawScores.negativeScore.toFixed(4)} (Net: {score.toFixed(4)})
        </p>
      )}
      {clickedNode && clickedScore !== null && (
        <p>
          Trust score from {selectedNode} to {clickedNode}: +
          {nodeScores[clickedNode].positive.toFixed(4)} / -
          {nodeScores[clickedNode].negative.toFixed(4)} (Net:{" "}
          {clickedScore.toFixed(4)})
        </p>
      )}
      <GraphContainer height={displayHeight - 400} width={displayWidth - 40}>
        <ForceGraph3D
          ref={fgRef}
          width={displayWidth - 40}
          height={displayHeight - 400}
          graphData={graphData}
          nodeLabel="id"
          nodeAutoColorBy="id"
          nodeThreeObject={(node: any) => {
            const group = new THREE.Group();

            const sprite = new SpriteText(node.id);
            sprite.color = node.color;
            sprite.textHeight = 8;
            group.add(sprite);

            if (node.id !== selectedNode) {
              const score = nodeScores[node.id];
              const scoreSprite = new SpriteText(
                `+${score?.positive.toFixed(2)} / -${score?.negative.toFixed(
                  2
                )}\n(${score?.net.toFixed(2)})`
              );
              scoreSprite.color = "black";
              scoreSprite.textHeight = 4;
              scoreSprite.position.y = -10;
              group.add(scoreSprite);
            }

            return group;
          }}
          linkDirectionalArrowLength={4.5}
          linkDirectionalArrowRelPos={1}
          linkCurvature={0.25}
          linkWidth={1}
          linkOpacity={0.6}
          linkLabel={(link: any) =>
            `${link.source.id} → ${
              link.target.id
            }: +${link.positiveWeight.toFixed(
              2
            )} / -${link.negativeWeight.toFixed(2)}`
          }
          onNodeClick={handleNodeClick}
          onNodeRightClick={handleNodeRightClick}
          backgroundColor="#ffffff"
          linkThreeObjectExtend={true}
          linkThreeObject={(link: any) => {
            const sprite = new SpriteText(
              `+${link.positiveWeight.toFixed(
                2
              )} / -${link.negativeWeight.toFixed(2)}`
            );
            sprite.color = "black";
            sprite.textHeight = 1.5;
            return sprite;
          }}
          linkPositionUpdate={(sprite, { start, end }, link) => {
            const middlePos = {
              x: start.x + (end.x - start.x) / 2,
              y: start.y + (end.y - start.y) / 2,
              z: start.z + (end.z - start.z) / 2,
            };
            // Calculate offset direction
            const offset = 3;
            const dx = end.x - start.x;
            const dy = end.y - start.y;
            const angle = Math.atan2(dy, dx) - Math.PI / 2; // Changed to subtract PI/2
            middlePos.x += Math.cos(angle) * offset;
            middlePos.y += Math.sin(angle) * offset;
            Object.assign(sprite.position, middlePos);
          }}
          forceEngine="d3"
        />
      </GraphContainer>

      <AddConnection
        newEdgeSource={newEdgeSource}
        setNewEdgeSource={setNewEdgeSource}
        newEdgeTarget={newEdgeTarget}
        setNewEdgeTarget={setNewEdgeTarget}
        newEdgeWeight={newEdgeWeight}
        setNewEdgeWeight={setNewEdgeWeight}
        handleAddEdge={handleAddEdge}
      />

      {dialogOpen && (
        <Dialog
          dialogNode={dialogNode}
          newNodeName={newNodeName}
          setNewNodeName={setNewNodeName}
          handleRenameNode={handleRenameNode}
          handleDeleteNode={handleDeleteNode}
          closeDialog={() => setDialogOpen(false)}
        />
      )}

      <ButtonContainer>
        <StyledButton onClick={handleExportGraph}>Export Graph</StyledButton>
        <label>
          <StyledButton as="span">Import Graph</StyledButton>
          <input
            type="file"
            accept=".json"
            onChange={handleImportGraph}
            style={{ display: "none" }}
          />
        </label>
      </ButtonContainer>
    </AppContainer>
  );
};

export default App;
